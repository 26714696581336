<template>
  <section>
    <v-card class="mb-8">
      <v-card-title>Produs de baza</v-card-title>
      <v-card-text>
        <base-product-select class="w-full mb-4"/>
        <short-name-recipe class="w-full"/>
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Subcategorii</v-card-title>
      <v-card-text>
        <category-selector ref="categorySelector" class="w-full sm:w-1/2 md:w-1/3"/>
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Configureaza Componentele</v-card-title>
      <v-card-text>
        <components-selector/>
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Extra Garantie</v-card-title>
      <v-card-text>
        <extra-warranty-selector/>
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Extra Optiuni</v-card-title>
      <v-card-text>
        <extra-options-selector></extra-options-selector>
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Publicarea produselor</v-card-title>
      <v-card-text>
        <publish-locations/>
      </v-card-text>
      <v-card-actions>
        <v-btn class="mr-2 primary-button" color="primary" type="filled" @click="generate">Salveaza</v-btn>
      </v-card-actions>
    </v-card>

  </section>
</template>

<script>

import PublishLocations from '@/views/ProductGenerator/components/PublishLocations'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import BaseProductSelect from '@/views/ProductGenerator/components/BaseProductSelect'
import CategorySelector from '@/views/ProductGenerator/components/CategorySelector'
import ComponentsSelector from '@/views/ProductGenerator/components/ComponentsSelector'
import lodash from 'lodash'
import ShortNameRecipe from '@/views/ProductGenerator/components/ShortNameRecipe'
import ExtraWarrantySelector from '@/views/ProductGenerator/components/ExtraWarrantySelector'
import ExtraOptionsSelector from "@/views/ProductGenerator/components/ExtraOptionsSelector.vue";

export default {
  name: 'ProductsGeneratorEdit',
  components: {
    ExtraWarrantySelector,
    ShortNameRecipe,
    ComponentsSelector,
    CategorySelector,
    BaseProductSelect,
    ErrorMessages,
    PublishLocations,
    ExtraOptionsSelector
  },
  computed: {
    itemId () {
      return this.$route.params.productConfigId
    }
  },
  methods: {
    generate () {
      this.$store.dispatch('productGenerator/regenerate', this.itemId)
        .then(() => {
          this.$router.push({
            name: 'products-category',
            params: {
              'categoryId': this.$store.getters['productGenerator/categoryId']
            }
          })
        })
    }
  },
  created () {
    this.$store.commit('productGenerator/RESET')
    this.$store.dispatch('startLoading')
    this.$store.commit('productGenerator/SET_IS_EDITING', true)
    this.$store.commit('productGenerator/SET_CATEGORY_ID', this.$route.params.categoryId)
    this.$http.get(`product-configs/${(this.itemId)}`)
      .then(({data}) => {
        this.$refs.categorySelector.selectCategories(data.categories)
        this.$store.commit('productGenerator/SET_BASE_PRODUCT_ID', data.base_product_id)

        this.$store.commit('productGenerator/SET_SHORT_NAME_RECIPE', data.short_name_recipe)
        this.$store.commit('productGenerator/SET_IS_PUBLISHED_TO_CIT', (data.is_published_to_cit ? 1 : 0))
        this.$store.commit('productGenerator/SET_IS_PUBLISHED_TO_RESELLERS', (data.is_published_to_resellers ? 1 : 0))
        this.$store.commit('productGenerator/SET_IS_PUBLISHED_TO_FEED', (data.is_published_to_feed ? 1 : 0))


        this.$store.commit('productGenerator/SET_BUFFER_WARRANTY', data.possible_extra_warranties)
        this.$store.commit('productGenerator/SET_WARRANTIES', data.possible_extra_warranties.map(item => item.id))
        this.$store.commit('productGenerator/SET_EXTRA_OPTIONS', data.extra_option_categories.map(item => {
            return {
              id: item.id,
              "name": item.internal_name
            }
        }))

        this.$store.commit('productGenerator/SET_SUBCATEGORIES', data.categories.map(item => item.id))
        this.$store.commit('productGenerator/SET_QUALITIES', data.categories.reduce((total, current) => {
          total[current.id] = data.qualities.filter(quality => quality.pivot.category_id === current.id)
            .map(quality => quality.id)
          return total
        }, {}))

        this.$store.commit('productGenerator/REPLACE_ALL_SLOTS', data.slots.map((item) => {
          const cachedComponentTypes = this.$store.getters['productGenerator/cachedComponentTypes']
          if (!lodash.find(cachedComponentTypes, o => o.id === item.component_type_id)) {
            this.$store.commit('productGenerator/ADD_COMPONENT_TYPE_TO_CACHE', item.component_type)
          }
          return {
            component_type_id: item.component_type_id,
            display_order: item.display_order,
            uuid: item.uuid,
            components: item.components.map((item) => {
              return {
                id: item.id,
                status: item.pivot.status
              }
            })
          }
        }))


      })
      .finally(() => {
        this.$store.dispatch('stopLoading')
      })
  }
}
</script>
